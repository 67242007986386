
import {
    CloudUploadOutlined
} from "@ant-design/icons-vue";

export default {
    components: {
        CloudUploadOutlined
    },
    emits: ["done", "update:visible"],
    props: {
        // 是否打开弹窗
        visible: Boolean,
        data: Object,
        apiList: Array,
        url: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            // 导入请求状态
            loading: false,
            message_type: "error",
            messages: [],
            formState: {},
            rules: {
                type: [{
                    required: true,
                    trigger: 'change',
                    type: 'number',
                    message: this.$t('必填')
                }]
            }
        };
    },
    watch: {
        visible(val) {
            if (!val) {
                this.formState = {};
            }
        }
    },
    methods: {
        /* 上传 */
        doUpload(file) {
            this.loading = true;
            this.messages = [];
            let formData = new FormData();

            formData.append("file", file.file);

            this.$http
                .post(this.url, formData)
                .then(res => {
                    this.loading = false;

                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.updateVisible(false);
                        this.$emit("done");
                    } else {
                        this.messages = res.data.msg.split("\n").map(a => {
                            var str_array = a.split(",");

                            return this.$t(str_array[0], str_array.slice(1));
                        });
                        this.message_type = "error";
                        this.message_visible = true;
                    }
                })
                .catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            return false;
        },
        /* 更新visible */
        updateVisible(value) {
            this.$emit("update:visible", value);
        }
    }
};
